import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import * as echarts from 'echarts'
// 引入自动更新提醒
// import "@/utils/auto-update.js"

import 'echarts-liquidfill'

import * as filters from "./filters"
// 引入 element-ui
import ElementUI from 'element-ui'
// 引入 element-ui 的 css 文件
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/common.scss' // 公共样式

import Ellipsis from 'vue-directive-ellipsis';
import 'vue-directive-ellipsis/dist/ellipsis.umd.css';

Vue.directive('ellipsis', Ellipsis);

import AFTableColumn from 'af-table-column' //table 列宽自适应
Vue.use(AFTableColumn)
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

// 声明使用 element-ui
Vue.use(echarts);
Vue.use(ElementUI);
Vue.prototype.axios = axios;
Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts
Vue.prototype.$am4core = am4core;
Vue.prototype.$am4charts = am4charts;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});