
export default {
  state: {
    expansion:true
  },
  getters: {
    expansion: state => state.expansion
  },
  mutations: {
    setExpansion (state, expansion) {
      state.expansion = expansion;
    }
  },
  actions: {

  }
}
