import Vue from 'vue'
import Vuex from 'vuex'

import app from './module/app'

Vue.use(Vuex)

export default new Vuex.Store({
   state: {
      openedTab: [],
      activeTab: '',
	  reportType: null,
	  processName:"",
	  testTxt: {"tips":sessionStorage.getItem("BgColorType")}
    },
    mutations: {
      addTab (state, componentName) {
        state.openedTab.push(componentName)
      },
      changeTab (state, componentName) {
        state.activeTab = componentName
      },
      deductTab (state, componentName) {
        let index = state.openedTab.indexOf(componentName)
        state.openedTab.splice(index, 1)
      },
	  //
	  SET_ReportType (state, type) {
	    state.reportType = type;
	  },
	  setProcessName(state, value) {
	    state.processName = value;
	  },
	  upDate(state,msg) {
		state.testTxt.tips= msg;
	   }
    },
	actions: {
	  //
	},
	modules: {
	  app
	}
})