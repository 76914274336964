import Vue from 'vue'
import VueRouter from 'vue-router'
import { encryptDes, decryptDes } from '@/common/js/des.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
	redirect:"/login"
  },	
  {
    path: '/login',
  	component:() => import('../views/Login.vue'),
  },	
  {
    path: '/home',
    name: 'Home',
    component:() => import('../views/Home.vue'),
	children:[
		{
		  path: '/ProductionManagementKanban',
		  name: 'ProductionManagementKanban',
		  component:() => import('../views/ProductionManagementKanban/ProductionManagementKanban.vue'),
		  meta:{pathname:"生产管理看板",activeMenu:"/ProductionManagementKanban",keepAlive:false}
		},
		{
		  path: '/EquipmentOperationMonitoring',
		  name: 'EquipmentOperationMonitoring',
		  component:() => import('../views/EquipmentOperationMonitoring/EquipmentOperationMonitoring.vue'),
			redirect:'/EquipmentOperationManage',
		  meta:{pathname:"设备运行监控",activeMenu:"/EquipmentOperationMonitoring",keepAlive:false},
			children:[
				{
					path: '/EquipmentOperationManage',
					name: 'EquipmentOperationManage',
					component:() => import('../views/EquipmentOperationManage/EquipmentOperationManage.vue'),
					meta:{pathname:"设备运行监控",activeMenu:"/EquipmentOperationMonitoring",keepAlive:false}
				},
				{
					path: '/EquipmentLayoutView',
					name: 'EquipmentLayoutView',
					component:() => import('../views/EquipmentOperationManage/EquipmentLayoutView.vue'),
					meta:{pathname:"设备总览",activeMenu:"/EquipmentOperationMonitoring",keepAlive:false}
				},
				{
					path: '/EquipmentOperaStatusView',
					name: 'EquipmentOperaStatusView',
					component:() => import('../views/EquipmentOperationManage/EquipmentOperaStatusView.vue'),
					meta:{pathname:"设备运行监控",activeMenu:"/EquipmentOperationMonitoring",keepAlive:false}
				},
				{
					path: '/StatusDetails',
					name: 'StatusDetails',
					component:() => import('../views/EquipmentOperationMonitoring/StatusDetails.vue'),
					meta:{pathname:"",activeMenu:"/EquipmentOperationMonitoring",keepAlive:false}
				},
				{
					path: '/EquipmentAlarmManagement',
					name: 'EquipmentAlarmManagement',
					component:() => import('../views/EquipmentOperationManage/EquipmentAlarmManagement.vue'),
					meta:{pathname:"设备运行状态监测",activeMenu:"/EquipmentOperationMonitoring",keepAlive:false}
				},
			]
		},
		{
		  path: '/MonitoringDetails',
		  name: 'MonitoringDetails',
		  component:() => import('../views/EquipmentOperationMonitoring/MonitoringDetails.vue'),
		  meta:{pathname:"设备运行监控详情",activeMenu:"/EquipmentOperationMonitoring",keepAlive:false}
		},
		// {
		//   path: '/StatusDetails',
		//   name: 'StatusDetails',
		//   component:() => import('../views/EquipmentOperationMonitoring/StatusDetails.vue'),
		//   meta:{pathname:"设备运行监控详情",activeMenu:"/EquipmentOperationMonitoring",keepAlive:false}
		// },
		{
		  path: '/EquipmentOperationAnalysis',
		  name: 'EquipmentOperationAnalysis',
		  component:() => import('../views/EquipmentOperationAnalysis/EquipmentOperationAnalysis.vue'),
		  meta:{pathname:"设备运行分析",activeMenu:"/EquipmentOperationAnalysis",keepAlive:false}
		},
		{
		  path: '/DevOpsManage',
		  name: 'DevOpsManage',
		  component:() => import('../views/DevOps/DevOpsManage.vue'),
		  meta:{pathname:"设备运维管理",activeMenu:"/DevOpsManage",keepAlive:false}
		},
		{
		  path: '/PermissionManagement',
		  name: 'PermissionManagement',
		  component:() => import('../views/PlatformPermissionManagement/PermissionManagement.vue'),
		  meta:{pathname:"平台权限管理",activeMenu:"/PermissionManagement",keepAlive:false}
		}
	]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
// 为路由对象添加beforeEach导航守卫
router.beforeEach((to, from, next) => {
	if (to.path === '/login') return next()
	// const tokenStr = decryptDes(sessionStorage.getItem("token"),'comencry1234');
	const tokenStr = sessionStorage.getItem("token");
	if (!tokenStr) return next('/login')
	next()
})

export default router
