<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import $ from 'jquery';
export default {
  name: 'App',
  data:function () {
	return{
		
	}
  },
  created(){
	setInterval(()=>{
		this.checkVersion()
	 }, 60000*2) //2分钟一次
  },
  mounted(){//刷新页面
   // 禁止选中网页上内容
	window.onselectstart = function () { return false; };
	
	let width=$("body").width();
	  if(width>=1200){
　　　　　　//此处1920为自己屏幕的宽度，40位基准值，一般为16px
		let fontsize=width/1920*40;//fontsize为当前屏幕的基数字体，相对于设计稿计算得到的。
		$("html").css("font-size",`${fontsize}px`)
	  }//当加载页面的时候设置生效
	  window.onresize = () => {//当页面尺寸改变的时候生效
		return (() => {
		  let width=$("body").width();
		  if(width>=1200){
			let fontsize=width/1920*40;
			$("html").css("font-size",`${fontsize}px`)
		  }
		})()
	  }
  },
  methods: {
	checkVersion () {
		this.axios.get("./version.json")
			.then(res => {
			if(localStorage.version==undefined){
				localStorage.setItem("version", res.data.version)
			}else{
				if(localStorage.version!=res.data.version){
					localStorage.removeItem("version");
					this.$router.go(0);
					this.$notify.info({
					  title: '消息',
					  message:'页面有新内容更新!'
					});
				}
			}
			})
		}  
  },
}
</script>
<style lang="scss">
body,html,#app {
  margin: 0;
  padding: 0 !important;
  height: 100%;
}
</style>